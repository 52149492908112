<template>
    <div class="course-swiper swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <div class="course-adv" @click="goToSpsspro">
                    <div class="adv-left pc-unshow">
                        <div v-if="isMobile" class="title">SPSSPRO<br />在线数据分析平台</div>
                        <div v-else class="title">SPSSPRO在线数据分析平台</div>
                        <div class="brief">
                            <span>免费在线</span>
                            <span class="split-line">｜</span>
                            <span>海量模型</span>
                            <span class="split-line">｜</span>
                            <span>智能分析</span>
                        </div>
                        <a class="btn-primary" href="https://www.spsspro.com/" target="_blank"
                            >立即使用</a
                        >
                    </div>
                    <div class="adv-right pc-unshow">
                        <wj-image :src="require('@/assets/images/top-logo.png')"></wj-image>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="intention-solicitation-course-adv" @click="goToWenjuan">
                    <div class="adv-left pc-unshow">
                        <div class="title">
                            市调分析师课程公选课<br />校企合作<span style="color: #1a78ff"
                                >意向征集通知</span
                            >
                        </div>

                        <a
                            class="btn-primary"
                            href="https://bbs.spsspro.com/news/261/"
                            target="_blank"
                            >点击了解</a
                        >
                    </div>
                    <div class="adv-right pc-unshow">
                        <wj-image
                            :src="require('@/assets/images/intention-top-logo.png')"
                        ></wj-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
    </div>
</template>
<script>
import 'swiper/dist/js/swiper';
import 'swiper/dist/css/swiper.css';
import Swiper from 'swiper';
import { Image } from '@wenjuan/ui';
export default {
    name: 'advert',
    data() {
        return {};
    },
    props: {
        isMobile: Boolean,
    },
    components: {
        'wj-image': Image,
    },
    mounted() {
        this.$nextTick(() => {
            this.initSwiper();
        });
    },
    methods: {
        initSwiper() {
            new Swiper('.course-swiper', {
                //direction: 'vertical', // 垂直切换选项
                //mousewheel: true, //滚轮
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    //自动开始
                    delay: 3000, //时间间隔
                    disableOnInteraction: true, //*手动操作轮播图后不会暂停*
                },
                loop: false, // 循环模式选项
                pauseOnMouseEnter: true, //鼠标置于swiper时暂停自动切换
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
        },
        goToSpsspro() {
            if (this.isMobile) {
                return;
            } else {
                window.open('https://www.spsspro.com/', '_blank');
            }
        },
        goToWenjuan() {
            if (this.isMobile) {
                return;
            } else {
                window.open('https://bbs.spsspro.com/news/261/', '_blank');
            }
        },
    },
};
</script>
<style scoped lang="scss">
.course-adv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background: #deebf8 url(~@/assets/images/analysis-platform-notice.png) no-repeat center fixed;
    // background: linear-gradient(270deg, rgba(255, 243, 249, 0.6) 0%, rgba(208, 229, 249, 0.6) 100%);
    box-sizing: border-box;
    cursor: pointer;
    .pc-unshow {
        display: none;
    }
    @media (max-width: 640px) {
        flex-direction: column;
        height: 480px;
        background: linear-gradient(
            270deg,
            rgba(255, 243, 249, 0.6) 0%,
            rgba(208, 229, 249, 0.6) 100%
        );
        .pc-unshow {
            display: block;
        }
    }
    .adv-right {
        width: 285px;
        height: 173px;
        margin-left: 300px;
        @media (max-width: 640px) {
            margin-left: 0;
            width: 331px;
            height: 201px;
        }
        .wj-image {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    .adv-left {
        @media (max-width: 640px) {
            text-align: center;
        }
        .title {
            font-size: 26px;
            color: #3c3c3c;
            @media (max-width: 640px) {
                font-size: 28px;
                line-height: 31px;
            }
        }
        .brief {
            font-size: 18px;
            color: #727272;
            margin-top: 10px;
            @media (max-width: 640px) {
                margin-top: 16px;
                font-size: 16px;
                line-height: 22px;
            }
            .split-line {
                margin: 0 10px;
            }
        }
        .btn-primary {
            width: 138px;
            height: 40px;
            border-radius: 20px;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
            background: linear-gradient(270deg, #2caaff 0%, #257bff 100%);
            font-size: 18px;
            margin-top: 30px;
            display: inline-block;
            @media (max-width: 640px) {
                margin-top: 24px;
                margin-bottom: 40px;
            }
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
.intention-solicitation-course-adv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    cursor: pointer;
    background: #e9f1f9 url(~@/assets/images/intention-solicitation-notice.png) no-repeat center
        fixed;
    // background: linear-gradient(270deg, rgba(255, 243, 249, 0.6) 0%, rgba(208, 229, 249, 0.6) 100%);
    box-sizing: border-box;
    .pc-unshow {
        display: none;
    }
    @media (max-width: 640px) {
        flex-direction: column;
        height: 480px;
        background: linear-gradient(
            270deg,
            rgba(255, 243, 249, 0.6) 0%,
            rgba(208, 229, 249, 0.6) 100%
        );
        cursor: default;
        .pc-unshow {
            display: block;
        }
    }
    .adv-right {
        width: 285px;
        height: 173px;
        margin-left: 300px;
        @media (max-width: 640px) {
            margin-left: 0;
            width: 331px;
            height: 201px;
        }
        .wj-image {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
    .adv-left {
        @media (max-width: 640px) {
            text-align: center;
        }
        .title {
            font-size: 26px;
            color: #3c3c3c;
            @media (max-width: 640px) {
                font-size: 28px;
                line-height: 38px;
            }
        }
        .btn-primary {
            width: 138px;
            height: 40px;
            border-radius: 20px;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
            background: linear-gradient(270deg, #00d2a2 0%, #00d2a2 100%);
            font-size: 18px;
            margin-top: 30px;
            display: inline-block;
            @media (max-width: 640px) {
                margin-top: 36px;
                margin-bottom: 30px;
            }
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>
