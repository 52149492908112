<template>
    <div class="course-item">
        <div class="course-item-content" @click="handleClickCourseItem">
            <img :src="img['content']" alt="课程封面" />
        </div>
        <div class="course-item-desc flex y-center x-between">
            <span>
                {{ renderType === 'school' ? itemData.school : itemData.title }}
                <!-- {{ itemData.school }} -->
            </span>
            <wj-dropdown @command="handleSelectMore" v-if="renderType === 'course' && isEdit">
                <i class="wj-icon-more" style="color: #3c3c3c"></i>
                <wj-dropdown-menu slot="dropdown">
                    <wj-dropdown-item command="edit" icon="wj-icon-edit"> 修改 </wj-dropdown-item>
                    <wj-dropdown-item command="delete" icon="wj-icon-delete">
                        删除
                    </wj-dropdown-item>
                </wj-dropdown-menu>
            </wj-dropdown>
        </div>

        <!-- 移动端-未登录弹窗 -->
        <wj-dialog
            :visible.sync="showDialogM1"
            width="327px"
            append-to-body
            title="提示"
            type="small-center"
        >
            <span class="dialog-content-m">未登录账号，请前往登录。</span>
            <span slot="footer" class="dialog-footer">
                <wj-button @click="showDialogM1 = false">取消</wj-button>
                <wj-button type="primary" @click="handleLoginM">登录</wj-button>
            </span>
        </wj-dialog>

        <!-- 移动端-无查看权限弹窗 -->
        <wj-dialog
            :visible.sync="showDialogM2"
            width="327px"
            append-to-body
            title="提示"
            type="small-center"
        >
            <span class="dialog-content-m">暂无查看该课程的权限。</span>
            <span slot="footer" class="dialog-footer">
                <wj-button type="primary" @click="showDialogM2 = false">确认</wj-button>
            </span>
        </wj-dialog>
    </div>
</template>

<script>
import { Button, Dialog, Dropdown, DropdownMenu, DropdownItem } from '@wenjuan/ui';
export default {
    data() {
        return {
            showDialogM1: false, // 移动端-未登录弹窗
            showDialogM2: false, // 移动端-无查看权限弹窗
        };
    },
    components: {
        'wj-dropdown': Dropdown,
        'wj-dropdown-menu': DropdownMenu,
        'wj-dropdown-item': DropdownItem,
        'wj-dialog': Dialog,
        'wj-button': Button,
    },
    created() {},
    methods: {
        handleSelectMore(command) {
            if (command === 'edit') {
                let course = {
                    ...this.itemData,
                    coverImage: this.img['content'],
                };
                this.$emit('editFn', course);
                return;
            }
            if (command === 'delete') {
                this.$emit('delFn', this.itemData.courseId);
                return;
            }
        },
        // 移动端未登录-去登录
        handleLoginM() {
            this.showDialogM1 = false;
            this.$emit('showLogin');
        },
        handleClickCourseItem() {
            if (!this.$store.state.userInfo.id) {
                if (this.isMobile) {
                    this.showDialogM1 = true;
                    return;
                } else {
                    this.$confirm('未登录账号，请前往登录。', '提示', {
                        confirmButtonText: '登录',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            this.$emit('showLogin');
                        })
                        .catch(() => {});
                    return;
                }
            }
            if (
                this.$store.state.userInfo.id != this.itemData.educateeId &&
                this.$store.state.userType != 'ADMIN' &&
                this.renderType === 'school'
            ) {
                if (this.isMobile) {
                    this.showDialogM2 = true;
                    return;
                } else {
                    this.$confirm('暂无查看该课程的权限。', '提示', {
                        confirmButtonText: '确认',
                    }).then(() => {});
                    return;
                }
            }

            // 学校页 -> 前往课程页
            if (this.renderType === 'school') {
                this.$router.push(
                    `/course/list?id=${this.itemData.educateeId}&name=${this.itemData.school}`
                );
                return;
            }
            // 课程页 -> 前往课程集页
            if (this.renderType === 'course') {
                let educateeId = this.$route.query.id;
                let query = {};
                if (educateeId) {
                    query = {
                        cid: this.itemData.courseId,
                        id: educateeId,
                    };
                } else {
                    query = {
                        cid: this.itemData.courseId,
                    };
                }
                if (this.$route.path.includes('/admin/course')) {
                    this.$router.push({ path: `/admin/catalog`, query });
                } else {
                    this.$router.push({ path: `/course/catalog`, query });
                }
                return;
            }
        },
    },
    props: {
        itemData: {
            type: Object,
            default() {
                return {};
            },
        },
        // 区分是学校列表还是课程课表
        renderType: {
            type: String,
            default() {
                return 'school';
            },
        },
        img: {
            type: Object,
            default() {
                return {};
            },
        },
        isEdit: {
            default: false,
        },
        isMobile: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.course-item {
    width: 310px;
    margin-right: 30px;
    margin-bottom: 40px;
    height: 240px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @media (max-width: 640px) {
        width: 160px;
        height: 131px;
        margin-right: 0;
        margin-bottom: 13px;
        border-radius: 4px;
        border: 1px solid #eef2f5;
        box-sizing: border-box;
    }
    &:hover {
        box-shadow: 0px 3px 4px 0 rgb(0 0 0 / 10%);
        transform: translate(0, -4px);
        transition: transform 0.4s ease;
    }
    // 每四个不要右margin
    &:nth-of-type(4n) {
        margin-right: 0;
    }
    .course-item-content {
        height: 180px;
        border-radius: 8px 8px 0px 0px;
        cursor: pointer;
        @media (max-width: 640px) {
            height: 98px;
            border-radius: 3px 3px 0px 0px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
        // background-color: #58a5f9;
    }
    .course-item-desc {
        height: 60px;
        padding: 0 20px;
        background-color: #fff;
        border-radius: 0px 0px 8px 8px;
        @media (max-width: 640px) {
            height: 32px;
            padding: 0 13px;
            line-height: 32px;
        }
        span {
            font-family: PingFangSC;
            font-size: 16px;
            // 单行溢出隐藏
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width: 640px) {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #3c3c3c;
            }
        }
    }
}
.wj-dialog__body {
    .dialog-content-m {
        margin-top: 30px;
        margin-bottom: 10px;
        display: inline-block;
        color: #707070;
        font-size: 14px;
    }
}
</style>
