import request from '../index.js';
// 未登录获取图片
export function httpGetPublicImages(imageId) {
    return request.get(`/api/v1/public/image/${imageId}`);
}
// 未登录获取学校分页
export function httpGetPublicSchoolList(params) {
    return request.get(`/api/v1/public/educatee/page`, params);
}

//批量获取图片
export function httpGetImageList(ids) {
    let query = ids.join('&imageId=');
    return request.get(`/api/v1/public/image/getListImage?imageId=${query}`);
}
